.sidebar{
    position: fixed;
    top: 0;
    z-index: 1111;
    width: 250px;
    height: 100vh;
    background-color: #47339E;
}
.sidemenu:hover{
    border-radius: 20px;
    background-color: #39297E;
}
.sidemenu{
    margin: 10px;
}
.sidemenu.active{
    border-radius: 20px;
    background-color: #39297E;
}
.sidemenu.active svg{
    color: #fff;
}
.sidemenu.active p{
    color: #fff;
}