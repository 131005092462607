.horizontal-scroll {
    overflow: hidden;
    width: 100%;
    font-size: 16px;
    font-weight: bold;
    color: #0c263a;
  }
  
  .scrolling-text {
    white-space: nowrap;
    animation: scroll-left 30s linear infinite; /* Adjust animation duration as needed */
  }
  
  @keyframes scroll-left {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  