@import url('https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,400;9..40,500;9..40,600;9..40,700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Sora:wght@400;500;600&display=swap');

.content{
  margin-top: 85px;
  margin-right: 10px;
  margin-bottom: 10px;
}
.css-19kzrtu {
  padding: 0px 20px !important;
}
.MuiTabs-flexContainer{
  justify-content: space-between;
  overflow-x: scroll;
}