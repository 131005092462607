.footerShape{
    position: absolute;
    content: "";
    height: 95%;
    width: 920px;
    border: 1px solid #fff;
    opacity: .06;
    padding: 40px;
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
    top: 0;
    left: -27%;
    transform: rotate(13deg);
}
.footerbg{
    position: absolute;
    content: "";
    height: 90%;
    width: 900px;
    background: linear-gradient(150.39deg, rgba(237, 242, 248, .22) 15.02%, #edf2f8 99.59%);
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
    top: 5%;
    left: 7%;
}
.footerlogo{
    position: relative;
}
.footerlogo::after{
    content: url(../../assets/images/uparrow.png);
    position: absolute;
    right: -20px;
    top: 0px;
    transform: scale(0.6);
}