.bannerBg{
    background: url(../../assets/images/banner_1.png);
    margin: 95px 0px 0px 0px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
.firstSection{
    margin: 123px 0px;
}
.headText{
    position: relative;
}
.headText::after{
    content: url(../../assets/images/coin.png);
    position: absolute;
    right: 0px;
    top: -100px;
}
.bannerThump::before{
    position: absolute;
    content: "";
    width: 200px;
    height: 100%;
    background: linear-gradient(160deg, rgba(229,237,248,0) 0, rgba(0,208,148,.32) 100%);
    left: 48%;
    bottom: 0;
}
.lastThump{
    position: relative;
}
.lastThump::after{
    content: url(../../assets/images/1.png);
    position: absolute;
    right: -50px;
    bottom: -50px;
}   


.secondSec{
    padding: 120px 0px;
    overflow: hidden;
    background-color: #00150f;
    background-image: url(../../assets/images/feature.png);
}
.secondSec::before{
    content: url(../../assets/images/coin2.png);
    position: absolute;
    left: 150px;
    top: 100px;
}
.commitImg{
    position: relative;
}
.commitProgress{
    position: absolute;
    top: 50px;
    left: 30px;
    width: 200px;
    padding: 20px 30px;
    background:hsla(0, 0%, 100%, .9);
    border-radius: 10px;
    box-shadow: -16px 32px 80px 0 rgba(61,51,0,.12);
}
.commitProgress1{
    position: absolute;
    bottom: 30px;
    right: 80px;
    width: 200px;
    padding: 20px 30px;
    background:hsla(0, 0%, 100%, .9);
    border-radius: 10px;
    box-shadow: -16px 32px 80px 0 rgba(61,51,0,.12);
}

.thirdSec{
    padding: 120px 0px;
    overflow: hidden;
}
.fourthSec{
    padding: 120px 0px;
    overflow: hidden;
}
.fifthSec{
    padding: 120px 0px;
    background-image: url(../../assets/images/pricing.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}
.priceTitle{
    position: relative;
}
.priceTitle::before{
    content: url(../../assets/images/uparrow.png);
    position: absolute;
    left: -350px;
    top: 0px;
}
.pricings{
    border: 2px solid transparent;
    transition: all 0.5s ease-in-out;
}
.pricings:hover{
    border-color: #00d094;
}
.pricings1{
    border: 2px solid #00d094;
    transition: all 0.5s ease-in-out;
}
.sixthSec{
    padding: 120px 0px;
    background-color: rgb(243 246 250);
}
.seventhSec{
    padding: 120px 0px;
}
.priceTitle1{
    position: relative;
}
.priceTitle1::before{
    content: url(../../assets/images/coin3.png);
    position: absolute;
    left: -150px;
    top: 0px;
}
.faq{
    background: radial-gradient(#e1ffe8, #fff);
}
.lastSection{
    padding: 100px 0px;
    overflow: hidden;
    background: url('../../assets/images/contactbg.png');
    background-position: center;
    background-repeat: no-repeat;
}
.contBox{
    position: relative;
}
.contBox::before{
    content: url(../../assets/images/coin.png);
    position: absolute;
    left: -100px;
    top: -50px;
}
.contBox::after{
    content: url(../../assets/images/coin3.png);
    position: absolute;
    right: -150px;
    bottom: -100px;
}


@media (max-width: 600px){
    .bannerThump::before{
       display: none;
    }
    .lastThump::after{
        right: 0px;
        bottom: -120px;
    } 
}