.landHeader{
    width: 100%;
    background-color: #fff;
    left: 50%;
    transform: translateX(-50%);
    box-shadow: 0px 0px 5px #eaeaea;
    z-index: 1111;
}
.landHeader1{
    width: 100%;
    background-color: #fff;
    left: 50%;
    transform: translateX(-50%);
    box-shadow: 0px 0px 5px #eaeaea;
    z-index: 1111;
    top: 0%;
}